import React from "react";
import { ConnectedRouter } from "connected-react-router";

import ScrollToTop from "./global/ScrollToTop";
import Application from "./Application";

const Router = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <Application />
      </ScrollToTop>
    </ConnectedRouter>
  );
};

export default Router;
